import React from 'react'
import Purchasereport from './Partials/Purchasereport'

const PurchaseReportMain = () => {
  return (
    <div>
        <Purchasereport />
    </div>
  )
}

export default PurchaseReportMain