import React from 'react'
import TableViewItem from './Partials/TableViewItem'

const ProductViewItems = () => {
  return (
    <div>
        <TableViewItem/>
    </div>
  )
}

export default ProductViewItems